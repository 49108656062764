import axios from "axios";

axios.interceptors.request.use(
  function(config) {
    NProgress.start();
    config.url = `/${locale}${config.url}`;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    NProgress.done();
    return response;
  },
  function(error) {
    NProgress.done();
    return Promise.reject(error);
  }
);

export default axios;
