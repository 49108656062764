<template>
  <div>
    <ol class="list-group list-group-numbered list-group-horizontal">
      <li class="list-group-item list-group-item-secondary d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold">
            {{ $t('floor.total_building_units') }}
          </div>
          {{ $t('floor.total_building_units_description') }}
        </div>
        <h3><span class="badge bg-light rounded-pill">{{ totalBuildingUnits }}</span></h3>
      </li>
      <li class="list-group-item list-group-item-success d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold">
            {{ $t('floor.occupied_building_units') }}
          </div>
          {{ $t('floor.occupied_building_units_description') }}
        </div>
        <h3><span class="badge bg-light rounded-pill">{{ buildingUnitsOccupied }}</span></h3>
      </li>
      <li class="list-group-item list-group-item-warning d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold">
            {{ $t('floor.no_show_building_units') }}
          </div>
          {{ $t('floor.no_show_building_units_description') }}
        </div>
        <h3><span class="badge bg-light rounded-pill">{{ buildingUnitsNoShow }}</span></h3>
      </li>
      <li class="list-group-item list-group-item-danger d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold">
            {{ $t('floor.vacant_and_ready_building_units') }}
          </div>
          {{ $t('floor.vacant_and_ready_building_units_description') }}
        </div>
        <h3><span class="badge bg-light rounded-pill">{{ buildingUnitsVacant }}</span></h3>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    floors: {
      type: Array,
      required: true,
      default: function () {
        [];
      }
    }
  },
  computed: {
    buildingUnitsOccupied: function () {
      return this.totalBuildingUnitsByStatus("hired");
    },
    totalBuildingUnits: function () {
      return this.floors.reduce(
        (sum, floor) => sum + floor.building_units.length,
        0
      );
    },
    buildingUnitsVacant: function () {
      return this.totalBuildingUnitsByStatus("empty");
    },
    buildingUnitsNoShow: function () {
      return this.totalBuildingUnitsByStatus("deposited");
    }
  },
  methods: {
    totalBuildingUnitsByStatus(status) {
      if (!this.floors) return;

      return this.floors.reduce(
        (sum, floor) =>
          sum +
          floor.building_units.filter(
            (building_unit) => building_unit.status == status
          ).length,
        0
      );
    }
  }
};
</script>
