<template>
  <div class="container-fluid">
    <div class="d-flex flex-row-reverse">
      <Paginator
        :current-page="currentPage"
        :show-paginate="showPaginate"
        :total-pages="totalPages"
        :total-count="totalCount"
        :load-objects="loadContractHistories"
      />
    </div>
    <TableResult :contract-histories="contractHistories" />
    <div class="d-flex flex-row-reverse">
      <Paginator
        :current-page="currentPage"
        :show-paginate="showPaginate"
        :total-pages="totalPages"
        :total-count="totalCount"
        :load-objects="loadContractHistories"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('contract_history');

import TableResult from './components_statistics/contract_histories/tableResult';
import Paginator from './components/paginator';

export default {
  components: {
    TableResult,
    Paginator
  },
  computed: {
    ...mapState(['contractHistories', 'params', 'showPaginate', 'currentPage', 'totalPages', 'totalCount'])
  },
  created: function() {
    const searchParams = new URLSearchParams(location.search);
    const urlParams = Object.fromEntries(searchParams.entries());
    const currentPage = urlParams.page ? urlParams.page : 1;
    this.loadContractHistories(currentPage);
  },
  methods: {
    ...mapActions(['submitFormSearch']),
    loadContractHistories(currentPage) {
      this.submitFormSearch({ params: this.params, page: currentPage });
    }
  }
};
</script>
