<template>
  <div id="app">
    <SearchForm />
    <div class="d-flex">
      <FlashMessage :position="'left top'" />
      <div :class="['new-user', { 'mb-2': !showPaginate }]">
        <a
          class="btn btn-primary"
          :href="newUserPath"
        >{{ $t('user.new_user') }}</a>
      </div>
      <div class="ml-auto">
        <Paginator
          :current-page="currentPage"
          :show-paginate="showPaginate"
          :total-pages="totalPages"
          :total-count="totalCount"
          :load-objects="loadUsers"
        />
      </div>
    </div>
    <TableUser
      :users="users"
    />
    <div class="d-flex flex-row-reverse">
      <Paginator
        :current-page="currentPage"
        :show-paginate="showPaginate"
        :total-pages="totalPages"
        :total-count="totalCount"
        :load-objects="loadUsers"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TableUser from './component_users/tableUsers.vue';
import Paginator from './components/paginator.vue';
import SearchForm from './component_users/searchForm';
import convert_param_url from './mixins/convert_param_url';

export default {
  components: {
    TableUser,
    Paginator,
    SearchForm
  },
  mixins: [convert_param_url],
  data: function() {
    return {
      newUserPath: `/${locale}/users/new`
    };
  },
  computed: {
    ...mapState({
      params: (state) => state.user.params,
      totalPages: (state) => state.user.totalPages,
      totalCount: (state) => state.user.totalCount,
      showPaginate: (state) => state.user.showPaginate,
      users: (state) => state.user.users
    }),
    currentPage: {
      get() {
        return this.$store.state.user.currentPage;
      },
      set(val) {
        this.$store.commit('user/setCurrentPage', val);
      }
    }
  },
  created: function() {
    const searchParams = new URLSearchParams(location.search);
    const urlParams = Object.fromEntries(searchParams.entries());
    const payload = this.convert_params(urlParams);
    const params = {
      params: payload || {},
      page: urlParams.page || 1
    };

    this.$store.dispatch('user/submitFormSearch', params);
  },
  methods: {
    loadUsers: function(current_page) {
      this.$store.dispatch('user/submitFormSearch', { params: this.params, page: current_page });
    }
  }
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
