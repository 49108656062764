<template>
  <tr class="text-center">
    <td>{{ revenue.date }}</td>
    <td>{{ revenue.building_unit_price | formatNumber }}</td>
    <td>{{ revenue.electrict_total | formatNumber }}</td>
    <td>{{ revenue.water_total | formatNumber }}</td>
    <td>{{ revenue.internet_total | formatNumber }}</td>
    <td>{{ revenue.park_fee_total | formatNumber }}</td>
    <td>{{ revenue.service_total | formatNumber }}</td>
    <td>{{ revenue.reduce | formatNumber }}</td>
    <td>{{ revenue.added | formatNumber }}</td>
    <td>{{ revenue.total | formatNumber }}</td>
  </tr>
</template>

<script>
export default {
  props: {
    revenue: {
      type: Object,
      required: true
    }
  }
};
</script>
