<template>
  <div class="bd-example w-100">
    <div class="btn-new-contract">
      <button
        class="btn btn-primary"
        @click="backToIndex"
      >
        {{ $t('contract.back') }}
      </button>
    </div>
    <hr>
    <div class="d-flex">
      <h3>{{ $t('contract.new_contract') }}</h3>
    </div>
    <hr>
    <form
      id="new-contract"
      class="w-90 ml-auto mr-auto"
      @submit.prevent="submitForm"
    >
      <div class="form-input p-2">
        <div class="form-row">
          <div class="form-group col-md-6">
            <InputSelect2
              v-model="holder_id"
              input-id="holder_id"
              :place-holder="$t('contract.select_holder')"
              :label-text="$t('contract.holder_name')"
              type-select="single_select"
              :options="collectionUsers"
              :selected="holder_id"
              :e-msg="errorMessages['holder_id']? errorMessages['holder_id'] : ''"
            />
          </div>
          <div class="form-group col-md-6">
            <InputSelect2
              v-model="building_unit_id"
              input-id="building_unit_id"
              :place-holder="$t('contract.select_building_unit')"
              :label-text="$t('contract.unit_number')"
              type-select="single_select"
              :options="collectionBuildingUnits"
              :selected="building_unit_id"
              :e-msg="errorMessages['building_unit_id']? errorMessages['building_unit_id'] : ''"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <InputNumberCurrency
              v-model="building_unit_price"
              input-id="building_unit_price"
              :label-text="$t('contract.building_unit_price')"
              :value-input="building_unit_price"
              :e-msg="errorMessages['building_unit_price']? errorMessages['building_unit_price'] : ''"
            />
          </div>
          <div class="form-group col-md-6">
            <InputNumberCurrency
              v-model="deposited_money"
              input-id="deposited_money"
              :label-text="$t('contract.deposited')"
              :value-input="deposited_money"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <InputDate
              v-model="started_date"
              :input-id="'started_date'"
              :label-text="$t('contract.start_date')"
              :language="$i18n.locale"
              :e-msg="errorMessages['started_date']? errorMessages['started_date'] : ''"
            />
          </div>
          <div class="form-group col-md-6">
            <InputDate
              v-model="ended_date"
              :input-id="'ended_date'"
              :label-text="$t('contract.end_date')"
              :language="$i18n.locale"
              :value-input="ended_date"
              :e-msg="errorMessages['ended_date']? errorMessages['ended_date'] : ''"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <div class="form-group row">
              <label
                :for="uniqueId('document_')"
                :class="['col-form-label', 'col-lg-2']"
              >{{ $t('contract.document') }}</label>
              <input
                :id="uniqueId('document_')"
                multiple
                :accept="'.jpg, .jpeg, .png, .doc, .docx, .xlsx, .xls, .pdf'"
                :placeholder="'.jpg, .jpeg, .png, .doc, .docx, .xlsx, .xls, .pdf'"
                :type="'file'"
                :class="'col-lg-10'"
                @change="handleChangeDocument"
              >
            </div>
          </div>
        </div>
      </div>
      <button class="btn btn-primary float-right mt-2">
        {{ $t('contract.submit') }}
      </button>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { uniqueId } from "lodash";
import InputSelect2 from "../components/inputSelect2";
import InputNumberCurrency from "../components/inputNumberCurrency";
import InputDate from "../components/inputDate";

export default {
  components: {
    InputSelect2,
    InputNumberCurrency,
    InputDate
  },
  data: function () {
    return {
      holder_id: "",
      building_unit_id: "",
      building_unit_price: "",
      deposited_money: "",
      started_date: "",
      ended_date: "",
      selectedFiles: []
    };
  },
  computed: {
    ...mapState({
      collectionBuildingUnits: (state) => state.contract.building_units,
      collectionUsers: (state) => state.contract.users,
      errorMessages: (state) => state.contract.errorMessages,
      isIndex: (state) => state.contract.isIndex
    })
  },
  watch: {
    building_unit_price: function (val) {
      this.deposited_money = val;
    },
    started_date: function (val) {
      const SIX_MONTH = 6;
      const target = new Date(val);
      target.setMonth(target.getMonth() + SIX_MONTH);
      this.ended_date = target;
    }
  },
  methods: {
    uniqueId,
    submitForm: function () {
      const self = this;
      const formData = new FormData();
      const params = {
        holder_id: this.holder_id,
        building_unit_id: this.building_unit_id,
        building_unit_price: numeral(this.building_unit_price).value(),
        deposited_money: numeral(this.deposited_money).value(),
        started_date: this.started_date,
        ended_date: this.ended_date
      };
      Object.keys(params).forEach((key) =>
        formData.append(`contract[${key}]`, params[key])
      );
      self.selectedFiles.forEach((file) =>
        formData.append(`contract[pictures][][file]`, file)
      );

      this.$store.dispatch("contract/createContract", { params: formData });
    },
    backToIndex: function () {
      this.$store.dispatch("contract/setIsIndex", true);
    },
    handleChangeDocument: function (event) {
      const self = this;
      const fileList = event.currentTarget.files || event.dataTransfer.files;
      for (const file of fileList) {
        self.selectedFiles.push(file);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-input {
  border: 2px solid#e8e7e7;
  background-color: #f3f3f3;
}
</style>
