import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import floor from './modules/floor';
import contract from './modules/contract';
import invoice from './modules/invoice';
import revenue from './modules/statistics/revenue';
import contract_history from './modules/statistics/contract_history';
import organization from './modules/organization';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    floor,
    contract,
    invoice,
    revenue,
    contract_history,
    organization
  }
});
