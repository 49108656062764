import invoice from '../../api/invoices';
import mixin from '../../mixins/handle_errors';

const state = {
  params: {},
  invoices: [],
  showPaginate: false,
  totalPages: 0,
  totalCount: 0,
  currentPage: 0,
  perPage: 0,
  resultMonths: [],
  resultYears: [],
  invoicesForm: {},
  inputForm: {},
  buildingUnits: {},
  newInvoice: {
    month: '',
    building_unit_id: '',
    building_unit_price: '',
    day_used_per_month: '',
    electric_start: '',
    electric_end: '',
    water_start: '',
    water_end: '',
    unit_price_internet: '',
    unit_price_parking_fee: '',
    quantity_parking: '',
    unit_price_service_fee: '',
    reduce: '',
    added: '',
    total: '',
    electric_unit_price: 0,
    water_unit_price: 0
  },
  currentInvoice: {},
  detailInvoice: {},
  errorMessages: {},
  isValid: true,
  flashMsg: '',
  loading: false
};

const actions = {
  submitFormSearch({ commit }, payload) {
    commit('setParams', payload);
    commit('setPerPage', Number(payload.perPage));
    const params = { q: payload.params, page: payload.page, per_page: payload.perPage };
    const path = `/invoices?${jQuery.param(params)}`;
    history.pushState('', '', path);
    invoice.loadWithCondition(params, (data) => {
      commit('setInvoices', data);
    });
  },
  getInvoices({ commit }, payload) {
    commit('setParams', payload);
    invoice.loadWithCondition({ q: payload.params, page: payload.page }, (invoices) => {
      commit('setInvoices', invoices);
    });
  },
  getInvoiceForm({ commit }, payload) {
    invoice.loadInvoiceForm(payload, (invoicesForm) => {
      commit('setInvoiceForm', invoicesForm);
    });
  },
  setInputForm({ commit }, payload) {
    commit('setInputForm', payload);
  },
  async createInvoices({ commit }, payload) {
    await invoice.createInvoices({ params: payload.params, month: payload.month }, (data) => {
      if (data.status === 'ok') {
        window.location.href = '/invoices';
      } else if (data.status === 'not_allow') {
        commit('setFlashMessage', data.errors);
      }
    });
  },
  getBuildingUnits({ commit }) {
    invoice.loadBuildingUnits({}, (building_units) => {
      commit('setBuildingUnits', building_units);
    });
  },
  async createInvoice({ commit }, payload) {
    const requestPayload = Object.assign({}, payload);
    delete requestPayload['building_unit_price'];
    await invoice.createInvoice(requestPayload, (data) => {
      if (data.status == 'ok') {
        window.location.href = '/invoices';
      } else if (data.status === 'not_allow') {
        commit('setFlashMessage', data.errors);
        dispatch('clearErrorMessages');
      } else {
        commit('setFlashMessage');
        commit('setErrors', mixin.methods.handle_errors({ 0: data.errors }));
      }
    });
  },
  setInputNewInvoiceForm({ commit }, payload) {
    commit('setInputNewInvoice', payload);
  },
  getDetailInvoice({ commit }, id) {
    invoice.loadDetailInvoice(id, (data) => {
      commit('setDetailInvoice', data);
    });
  },
  setCurrentInvoice({ commit }, payload) {
    commit('setCurrentInvoice', payload);
  },
  async updateInvoice({ commit, dispatch, state }, payload) {
    const params = { ...payload };
    await invoice.updateInvoice(params, (data) => {
      if (data.step === 'confirmation' && data.status === 'ok') {
        commit('setStatusResponse', true);
      } else if (data.step === 'confirmation' && data.status != 'ok') {
        commit('setStatusResponse', false);
        commit('setErrors', mixin.methods.handle_errors({ 0: data.errors }));
      } else if (data.step === 'done' && data.status === 'ok') {
        commit('setStatusResponse', true);
        dispatch('submitFormSearch', { params: state.params, page: state.currentPage, perPage: state.perPage });
      } else {
        commit('setStatusResponse', false);
        dispatch('submitFormSearch', { params: state.params, page: state.currentPage, perPage: state.perPage });
      }
    });
  },
  clearErrorMessages({ commit }, _payload) {
    commit('clearErrorMessages');
  },
  deleteInvoice({ commit, dispatch, state }, payload) {
    invoice.deleteInvoice(payload.params, (data) => {
      dispatch('submitFormSearch', { params: state.params, page: state.currentPage });
    });
  },
  getResourceInfo({ commit }, payload) {
    commit('setLoading', true);
    invoice.getResourceInfo(payload, (data) => {
      commit('setInputNewInvoice', data.build);
      commit('setLoading', false);
    });
  }
};

const mutations = {
  setParams(state, payload) {
    state.params = payload.params;
    state.currentPage = Number(payload.page);
    state.perPage = Number(payload.perPage);
  },
  setInvoiceForm(state, invoicesForm) {
    state.invoicesForm = invoicesForm.build;
  },
  setInputForm(state, payload) {
    state.inputForm[payload.unitNumber] = payload.form;
  },
  setInvoices(state, data) {
    state.currentPage = Number(data.current_page);
    state.totalPages = data.total_pages;
    state.totalCount = data.total_count;
    state.resultMonths = data.months;
    state.resultYears = data.years;
    state.showPaginate = data.total_pages > 1;
    state.perPage = data.per_page;
    state.invoices = data.data;
  },
  setBuildingUnits(state, building_units) {
    state.buildingUnits = building_units;
  },
  setErrors(state, errorMessages) {
    state.errorMessages = errorMessages;
  },
  setInputNewInvoice(state, payload) {
    for (const key in payload) {
      state.newInvoice[key] = payload[key];
    }
  },
  setDetailInvoice(state, data) {
    state.detailInvoice = data.invoice;
  },
  setCurrentInvoice(state, data) {
    state.currentInvoice = data;
  },
  setStatusResponse(state, status) {
    state.isValid = status;
  },
  clearErrorMessages(state, _payload) {
    state.errorMessages = '';
  },
  setFlashMessage(state, msg = '') {
    state.flashMsg = msg;
  },
  setPerPage(state, perPage) {
    state.perPage = perPage;
  },
  setLoading(state, payload) {
    state.loading = payload;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
