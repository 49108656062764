<template>
  <div class="table-responsive">
    <table class="table table-bordered table-striped">
      <thead class="table-header">
        <tr class="text-center">
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('invoice.invoice_id') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('invoice.building_unit') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('invoice.month') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('invoice.price') }}
          </th>
          <th
            scope="col"
            class="align-middle"
            role="button"
            @click="switchBetweenMoneyAndQuantityOfElectric"
          >
            <i class="fas fa-angle-left" />
            {{ $t('invoice.electric') }}
            <i class="fas fa-angle-right" />
          </th>
          <th
            scope="col"
            class="align-middle"
            role="button"
            @click="switchBetweenMoneyAndQuantityOfWater"
          >
            <i class="fas fa-angle-left" />
            {{ $t('invoice.water') }}
            <i class="fas fa-angle-right" />
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('invoice.internet') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('invoice.unit_price_parking_fee') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('invoice.service') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('invoice.reduce') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('invoice.added') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('invoice.total_income') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('invoice.details') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('invoice.edit_or_delete') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('invoice.export_print') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <RowInvoice
          v-for="(invoice, index) in invoices"
          :key="index"
          :invoice="invoice"
          :electric-consume-shown="electricConsumeShown"
          :water-consume-shown="waterConsumeShown"
        />
      </tbody>
      <EditInvoice ref="modal_edit" />
      <DetailInvoice />
      <DeleteInvoice />
    </table>
  </div>
</template>

<script>
import RowInvoice from "./rowInvoice";
import DetailInvoice from "./detailInvoice";
import EditInvoice from "./editInvoice";
import DeleteInvoice from "./deleteInvoice";

export default {
  components: {
    RowInvoice,
    DetailInvoice,
    EditInvoice,
    DeleteInvoice
  },
  props: {
    invoices: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      electricConsumeShown: false,
      waterConsumeShown: false
    };
  },
  methods: {
    switchBetweenMoneyAndQuantityOfElectric() {
      this.electricConsumeShown = !this.electricConsumeShown;
    },
    switchBetweenMoneyAndQuantityOfWater() {
      this.waterConsumeShown = !this.waterConsumeShown;
    }
  }
};
</script>
