<template>
  <div id="app">
    <TableOrganizations :organizations="organizations" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import TableOrganizations from './components_organizations/table.vue';
import convert_param_url from './mixins/convert_param_url';

export default {
  name: 'Organizations',
  components: {
    TableOrganizations
  },
  mixins: [convert_param_url],
  computed: {
    ...mapState({
      params: (state) => state.organization.params,
      totalPages: (state) => state.organization.totalPages,
      totalCount: (state) => state.organization.totalCount,
      showPaginate: (state) => state.organization.showPaginate,
      organizations: (state) => state.organization.organizations
    }),
    currentPage: {
      get() {
        return this.$store.state.organization.currentPage;
      },
      set(val) {
        this.$store.commit('organization/setCurrentPage', val);
      }
    }
  },
  created: function () {
    const searchParams = new URLSearchParams(location.search);
    const urlParams = Object.fromEntries(searchParams.entries());
    const payload = this.convert_params(urlParams);
    const params = {
      params: payload || {},
      page: urlParams.page || 1
    };

    this.$store.dispatch('organization/submitFormSearch', params);
  }
};
</script>
