import user from "../../api/users";
import floor from "../../api/floors";

const state = {
  floors: [],
  currentBuildingUnit: {},
  users: {},
  buildingUnitStatus: ["deposited", "empty"],
  isValid: true
};

const actions = {
  getFloors({ commit }) {
    floor.loadWithCondition({}, (floors) => {
      commit("setFloors", floors);
    });
  },
  setCurrentBuildingUnit({ commit }, building_unit) {
    commit("setCurrentBuildingUnit", building_unit);
  },
  getUsers({ commit }, payload) {
    user.loadUsers(payload.params, (users) => {
      commit("setUsers", users);
    });
  },
  async editBuildingUnit({ commit, dispatch }, payload) {
    await floor.updateBuildingUnit({ params: payload.params, id: payload.id }, (data) => {
      if (data.status === "ok") {
        commit("setStatusResponse", true);
      } else {
        commit("setStatusResponse", false);
      }
      dispatch("getFloors", {});
    });
  }
};

const mutations = {
  setFloors(state, data) {
    state.floors = data.data;
  },
  setCurrentBuildingUnit(state, building_unit) {
    state.currentBuildingUnit = building_unit;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setForm(state, params) {
    state.params = params;
  },
  setStatusResponse(state, status) {
    state.isValid = status;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
