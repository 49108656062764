<template>
  <div class="form-group row">
    <label
      :for="inputId"
      :class="['col-form-label', colLabel]"
    >{{ labelText }}</label>
    <div :class="[colInput]">
      <select
        :id="inputId"
        :ref="typeSelect"
        class="form-control"
      >
        <option />
        <option
          v-for="(value, key) in options"
          :key="key"
          :value="key"
        >
          {{ value }}
        </option>
      </select>
      <span
        v-if="eMsg"
        class="text-danger"
      >{{ eMsg }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inputId: {
      type: String,
      required: true
    },
    labelText: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    selected: {
      type: [Number, String],
      default: 0
    },
    typeSelect: {
      type: String,
      required: true
    },
    eMsg: {
      type: String,
      default: ""
    },
    placeHolder: {
      type: String,
      default: ""
    },
    colLabel: {
      type: String,
      default: "col-lg-2"
    },
    colInput: {
      type: String,
      default: "col-lg-10"
    }
  },
  data: function () {
    return {
      valSelect: this.selected
    };
  },
  watch: {
    selected(newValue) {
      $(`#${this.inputId}`).val(newValue).trigger("change");
    }
  },
  mounted: function () {
    const vm = this;
    return $(`#${vm.inputId}`)
      .select2({
        data: vm.options,
        width: "100%",
        allowClear: true,
        placeholder: vm.placeHolder
      })
      .on("select2:select select2:unselect", (e) => {
        vm.$emit("input", e.target.value);
      })
      .on("select2:open", () => {
        $(".select2-search__field")[0].focus();
      });
  },
  destroyed() {
    $(`#${this.inputId}`).off().select2("destroy");
  }
};
</script>
<style lang="scss" scoped>
.form {
  margin-bottom: 6px;
}
</style>
