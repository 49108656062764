<template>
  <table class="table table-striped table-bordered">
    <thead class="table-header">
      <tr class="text-center">
        <th
          scope="col"
          class="align-middle"
        >
          {{ $t('contract.contract_id') }}
        </th>
        <th
          scope="col"
          class="align-middle"
        >
          {{ $t('contract.holder_name') }}
        </th>
        <th
          scope="col"
          class="align-middle"
        >
          {{ $t('contract.unit_number') }}
        </th>
        <th
          scope="col"
          class="align-middle"
        >
          {{ $t('contract.building_unit_price') }}
        </th>
        <th
          scope="col"
          class="align-middle"
        >
          {{ $t('contract.deposited_money') }}
        </th>
        <th
          scope="col"
          class="align-middle"
        >
          {{ $t('contract.start_date') }}
        </th>
        <th
          scope="col"
          class="align-middle"
        >
          {{ $t('contract.end_date') }}
        </th>
        <th
          scope="col"
          class="align-middle col-1"
        >
          {{ $t('contract.duplicate_and_edit') }}
        </th>
        <th
          scope="col"
          class="align-middle"
        >
          {{ $t('contract.extend') }}
        </th>
        <th
          scope="col"
          class="align-middle"
        >
          {{ $t('contract.details') }}
        </th>
        <th
          scope="col"
          class="align-middle"
        >
          {{ $t('contract.terminate') }}
        </th>
        <th
          scope="col"
          class="align-middle"
        >
          {{ $t('contract.status') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <RowContract
        v-for="(contract, index) in contracts"
        :key="index"
        :contract="contract"
      />
    </tbody>
    <DetailContract ref="detail_contract" />
    <TerminateContract />
    <ExtendContract />
    <DuplicateAndEditContractModal />
  </table>
</template>

<script>
import RowContract from './rowContract';
import DetailContract from './detailContract';
import TerminateContract from './terminateContract';
import ExtendContract from './extendContract';
import DuplicateAndEditContractModal from './duplicateAndEditContractModal';

export default {
  components: {
    RowContract,
    DetailContract,
    TerminateContract,
    ExtendContract,
    DuplicateAndEditContractModal
  },
  props: {
    contracts: {
      type: Array,
      required: true
    }
  }
};
</script>
