<template>
  <div id="app">
    <div class="container">
      <FlashMessage :position="'right top'" />
      <h1 class="mt-2">
        {{ $t('admin.profile') }}
      </h1>
      <div class="row">
        <div class="col-8">
          <form
            id="edit-admin-profile"
            @submit.prevent="submitForm"
          >
            <FieldConfirm
              class="w-100"
              :label-text="$t('admin.email')"
              col-label="col-lg-12 font-weight-bold"
              col-input="col-lg-12"
              :value="params.email"
            />
            <InputText
              v-model="params.fullName"
              class="w-100"
              col-label="col-lg-12 font-weight-bold"
              col-input="col-lg-12"
              input-id="full_name"
              :label-text="$t('admin.full_name')"
              :value-input="params.fullName"
              :e-msg="editAdminProfileErrors['full_name'] | toString"
            />
            <InputText
              v-model="params.phone"
              class="w-100"
              col-label="col-lg-12 font-weight-bold"
              col-input="col-lg-12"
              input-id="phone"
              :label-text="$t('admin.phone')"
              :value-input="params.phone"
              :e-msg="editAdminProfileErrors['phone'] | toString"
            />
            <InputText
              v-model="params.birthday"
              class="w-100"
              col-label="col-lg-12 font-weight-bold"
              col-input="col-lg-12"
              input-id="birthday"
              type-input="date"
              :label-text="$t('admin.birthday')"
              :value-input="params.birthday"
              :e-msg="editAdminProfileErrors['birthday'] | toString"
            />
            <div class="form-group row w-100">
              <div class="col-lg-12">
                <button
                  class="btn btn-lg btn-success col-lg-12"
                >
                  {{ $t('admin.save') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import admin from './api/admins';
import show_flash_mixins from './mixins/show_flash';
import InputText from './components/inputText.vue';
import FieldConfirm from './components/fieldConfirm.vue';

export default {
  components: {
    InputText,
    FieldConfirm
  },
  filters: {
    toString: function(value) {
      if (!value) return '';

      return value.toString();
    }
  },
  mixins: [show_flash_mixins],
  data() {
    return {
      params: {
        fullName: "",
        phone: "",
        birthday: ""
      },
      editAdminProfileErrors: {
}
    };
  },
  created: function() {
    admin.loadDetailAdmin({ id: adminId }, (data) => {
      const self = this;
      self.params.fullName = data.admin.full_name;
      self.params.phone = data.admin.phone;
      self.params.birthday = data.admin.birthday;
      self.params.email = data.admin.email;
    });
  },
  methods: {
    submitForm() {
      const self = this;
      const payload = {
        full_name: self.params.fullName,
        phone: self.params.phone,
        birthday: self.params.birthday
      };
      admin.updateAdmin(payload, (data) => {
        self.editAdminProfileErrors = {};
        if(data.error) {
          self.editAdminProfileErrors = data.error;
        } else {
          self.show_flash(true);
        }
      }); 
    }
  } 
};
</script>
