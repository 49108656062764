import contractHistories from '../../../api/statistics/contract_histories';

const state = {
  params: {},
  showPaginate: false,
  currentPage: 1,
  totalPages: 1,
  totalCount: 1,
  contractHistories: []
};

const actions = {
  submitFormSearch({ commit }, payload) {
    const path = `/${locale}/statistics/contract_histories?${jQuery.param(payload)}`;
    history.pushState('', '', path);
    contractHistories.loadWithCondition({ q: payload.params, page: payload.page }, (data) => {
      commit('setContractHistories', data);
    });
  }
};

const mutations = {
  setParams(state, payload) {
    state.params = payload;
  },
  setContractHistories(state, payload) {
    state.contractHistories = payload.data;
    state.currentPage = Number(payload.meta.page);
    state.totalPages = payload.meta.total_pages;
    state.totalCount = payload.meta.total_result;
    state.showPaginate = payload.meta.total_pages > 1;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
