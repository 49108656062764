<template>
  <div id="app">
    <SearchForm />
    <div :class="['d-flex', {'mb-2': !showPaginate}]">
      <div class="ml-auto">
        <Paginator
          :current-page="currentPage"
          :show-paginate="showPaginate"
          :total-pages="totalPages"
          :total-count="totalCount"
          :load-objects="loadRevenues"
        />
      </div>
      <br>
    </div>
    <TableResult :revenues="revenues" />
    <div class="d-flex flex-row-reverse">
      <Paginator
        :current-page="currentPage"
        :show-paginate="showPaginate"
        :total-pages="totalPages"
        :total-count="totalCount"
        :load-objects="loadRevenues"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('revenue');

import TableResult from './components_statistics/revenues/tableResult';
import SearchForm from './components_statistics/revenues/searchForm';
import Paginator from './components/paginator';

export default {
  components: {
    SearchForm,
    TableResult,
    Paginator
  },
  computed: {
    ...mapState(['revenues', 'currentPage', 'totalPages',
                 'totalCount', 'showPaginate', 'params'])
  },
  mounted() {
    this.getOptionBuildingUnits();
  },
  methods: {
    ...mapActions(['getOptionBuildingUnits', 'submitFormSearch']),
    async loadRevenues(current_page) {
      $("#loading").removeClass("d-none");
      $("#body-content").addClass("body-opacity-50");
      await this.submitFormSearch({ params: this.params, page: current_page });
      $("#loading").addClass("d-none");
      $("#body-content").removeClass("body-opacity-50");
    }
  }
};
</script>
