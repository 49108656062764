<template>
  <div>
    <RowFloor
      v-for="(floor, index) in floors"
      :key="index"
      :floor="floor"
    />
    <EditBuildingUnit />
  </div>
</template>

<script>
import RowFloor from './rowFloor.vue';
import EditBuildingUnit from './editBuildingUnit.vue';

export default {
  components: {
    RowFloor,
    EditBuildingUnit
  },
  props: {
    floors: {
      type: Array,
      required: true
    }
  }
};
</script>
