import axios from "./axios";

export default {
  loadDetailAdmin(params, cb) {
    axios.get('/admin.json', {
    params: {
              id: params.id
            }
    }).then(function(response) {
      cb(response.data);
    }).catch(function(error) {
      console.log(error.response);
    });
  },
  updateAdmin(params, cb) {
    axios.patch('/admin', { admin: params }, {
      headers: {
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content') 
      }
    }).then(res => cb(res.data)).catch(error => console.log(error));
  }
};
