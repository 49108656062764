<template>
  <div class="form-group row">
    <label
      :for="inputId"
      :class="['col-form-label', colLabel]"
    >{{ labelText }}</label>
    <div :class="[colInput]">
      <Datepicker
        :id="inputId"
        v-model="inputVal"
        name="month_export-invoice"
        input-class="form-control"
        :wrapper-class="wrapperClass"
        :language="locale[language]"
        :format="dateFormat"
        :minimum-view="minimumView"
        :maximum-view="maximumView"
        :disabled-dates="disabledDates"
      />
      <span
        v-if="eMsg"
        class="text-danger"
      >{{ eMsg }}</span>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { en, vi } from 'vuejs-datepicker/dist/locale';

export default {
  components: {
    Datepicker
  },
  props: {
    inputId: {
      type: String,
      required: true
    },
    labelText: {
      type: String,
      required: true
    },
    valueInput: {
      type: [String, Date],
      default: function() {
        return new Date();
      }
    },
    eMsg: {
      type: String,
      default: ''
    },
    colLabel: {
      type: String,
      default: 'col-lg-2'
    },
    colInput: {
      type: String,
      default: 'col-lg-10'
    },
    language: {
      type: String,
      default: 'vi'
    },
    dateFormat: {
      type: String,
      default: 'dd/MM/yyyy'
    },
    minimumView: {
      type: String,
      default: 'day' 
    },
    maximumView: {
      type: String,
      default: 'year'
    },
    wrapperClass: {
      type: String,
      default: ''
    },
    disabledDates: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      inputVal: this.valueInput,
      locale: {
        'en': en,
        'vi': vi
      }
    };
  },
  watch: {
    inputVal: function(val) {
      return this.$emit('input', val);
    },
    valueInput: function(val) {
      return this.inputVal = val;
    }
  }
};
</script>

<style scoped>
  .z-1019 {
    z-index: 1019;
  }
</style>
