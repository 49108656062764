function togglePassword() {
  $('.show-password').on('click', () => {
    $('.show-password').toggleClass('d-none');
    if ($('#admin_password').prop('type') === 'password') {
      $('#admin_password').attr('type', 'text');
    } else {
      $('#admin_password').attr('type', 'password');
    }
  });
}

$(function() {
  togglePassword();
});
