<template>
  <table
    class="table table-bordered table-striped"
    @contextmenu.prevent="$refs.menu.open($event)"
  >
    <ContextMenu ref="menu">
      <ContextMenuItem @click.native="editOrganization()">
        {{ $t('organization.edit_organization') }}
      </ContextMenuItem>
      <ContextMenuItem @click.native="deleteOrganization()">
        {{ $t('organization.delete_organization') }}
      </ContextMenuItem>
    </ContextMenu>
    <thead class="table-header">
      <tr class="text-center">
        <td class="w-100px align-middle">
          {{ $t('organization.organization_id') }}
        </td>
        <td class="w-200px align-middle">
          {{ $t('organization.name') }}
        </td>
        <td class="w-200px align-middle">
          {{ $t('organization.description') }}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(organization, index) in organizations"
        :key="index"
        class="text-center"
        @contextmenu.prevent="setCurrentUser"
      >
        <td class="align-middle">
          {{ organization.id }}
        </td>
        <td class="align-middle">
          {{ organization.name }}
        </td>
        <td class="align-middle">
          {{ organization.description }}
        </td>
      </tr>
    </tbody>
    <!-- <EditOrganization ref="modal_edit" />
    <DeleteOrganization ref="modal_delete" /> -->
  </table>
</template>

<script>
// import EditOrganization from './editOrganization.vue'
// import DeleteOrganization from './deleteOrganization.vue'
import ContextMenu from '../components/contextMenu/contextMenu.vue';
import ContextMenuItem from '../components/contextMenu/contextMenuItem.vue';

export default {
  name: 'TableOrganizations',
  components: {
    // EditOrganization,
    // DeleteOrganization,
    ContextMenu,
    ContextMenuItem
  },
  props: {
    organizations: {
      type: Array,
      required: true
    }
  },
  methods: {
    editOrganization: function () {
      const element = this.$refs.modal_edit.$el;
      $(element).modal('show');
    },
    deleteOrganization: function () {
      const element = this.$refs.modal_delete.$el;
      $(element).modal('show');
    }
  }
};
</script>
