import { forEach } from 'lodash';

export default {
  data() {
    return {
      KEY_MATCH: /^q\[(\w+)\]$/
    };
  },
  methods: {
    convert_params(params) {
      const self = this;
      let newParams = {};
      forEach(params, function(value, key) {
        if(!key.startsWith('q[')) return;

        const newKey = key.match(self.KEY_MATCH)[1];
        newParams[newKey] = value;
      });

      return newParams;
    }
  }
};
