<template>
  <div
    v-if="message"
    id="flash"
    class="fadeIn d-flex justify-content-start mt-2 mb-2"
  >
    <div
      :class="['alert alert-dismissible fade show w-100',
               status ? 'alert-success' : 'alert-danger']"
      @click="onCloseFlash"
    >
      <button
        class="close"
        data-dismiss="alert"
        type="button"
        @click="onCloseFlash"
      >
        ×
      </button>
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },
  methods: {
    onCloseFlash() {
      this.$emit('onCloseFlash');
    }
  }
};
</script>
