<template>
  <div
    :id="contentBuildingUnit"
    class="mw-20 col d-flex flex-column"
    data-toggle="modal"
    data-target="#editBuildingUnit"
    @click="setCurrentBuildingUnit"
  >
    <div :class="['dot dot-lg ml-auto', statusBuildingUnit]" />
    <div class="text-center">
      {{ buildingUnit.unit_number }}
    </div>
    <div class="d-flex mt-auto">
      <div class="mr-auto p-2 w-100px">
        <i class="fas fa-home icon-color" />
        {{ buildingUnit.area }} m<sup>2</sup>
      </div>
      <div class="p-2">
        <i class="fas fa-users icon-color" />
        {{ buildingUnit.total_users }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buildingUnit: {
      type: Object,
      required: true
    }
  },
  computed: {
    contentBuildingUnit: function() {
      return `content_building_unit_${this.buildingUnit.unit_number}`;
    },
    statusBuildingUnit: function() {
      return this.buildingUnit.status;
    }
  },
  methods: {
    setCurrentBuildingUnit: function() {
      this.$store.dispatch('floor/setCurrentBuildingUnit', this.buildingUnit);
      this.$store.dispatch('floor/getUsers', { params: { building_unit_id: this.buildingUnit.id } });
    }
  }
};
</script>

<style lang="scss" scoped>
  .mw-20 {
    max-width: 20% !important;
  }
</style>
