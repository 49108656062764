// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');
window.numeral = require('numeral');
window.domtoimage = require('dom-to-image');
window.NProgress = require('nprogress');
NProgress.configure({ showSpinner: false });

import 'bootstrap';
import '../stylesheets/application.scss';
import $ from 'jquery';
import 'select2';
import 'bootstrap-fileinput/js/fileinput.min.js';
import '../common/base';
import FlashMessage from '@smartweb/vue-flash-message';

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '../store';
import Users from '../user';
import NewUser from '../newUser';
import Floors from '../floor';
import Contracts from '../contract';
import Invoices from '../invoice';
import BatchCreateNewInvoices from '../batchCreateNewInvoice';
import PrintInvoice from '../printInvoice';
import NewInvoice from '../newInvoice';
import RevenueStatistics from '../revenueStatistics';
import ContractHistoryStatistics from '../contractHistoryStatistics';
import ListInvoices from '../listInvoices';
import EditAdminProfile from '../editAdminProfile';
import Organizations from '../organizations';

Vue.use(FlashMessage);
Vue.use(VueI18n);

Vue.filter('formatNumber', function(value) {
  return numeral(value).format('0,0');
});

document.addEventListener('DOMContentLoaded', () => {
  const elementUser = document.getElementById('userApp');
  const elementNewUser = document.getElementById('newUserApp');
  const elementFloor = document.getElementById('floorApp');
  const elementContract = document.getElementById('contractApp');
  const elementInvoice = document.getElementById('invoiceApp');
  const elementBatchCreateNewInvoiceApp = document.getElementById('batchCreateNewInvoiceApp');
  const elementPrintInvoiceApp = document.getElementById('printInvoiceApp');
  const elementNewInvoice = document.getElementById('newInvoiceApp');
  const elementRevenueStatistics = document.getElementById('revenueStatisticsApp');
  const elementContractHistoryStatistics = document.getElementById('contractHistoryStatisticsApp');
  const elementListInvoices = document.getElementById('listInvoicesApp');
  const elementEditAdminProfileApp = document.getElementById('editAdminProfileApp');
  const organizationsApp = document.getElementById('organizationsApp');

  const i18n = new VueI18n({
    locale: locale,
    messages: loadLocaleMessages()
  });

  if (elementUser != null) {
    const userApp = new Vue({
      el: '#userApp',
      store,
      i18n,
      render: (h) => h(Users, {})
    });
  }

  if (elementNewUser != null) {
    const newUserApp = new Vue({
      el: '#newUserApp',
      store,
      i18n,
      render: (h) => h(NewUser, {})
    });
  }

  if (elementFloor != null) {
    const floorApp = new Vue({
      el: '#floorApp',
      store,
      i18n,
      render: (h) => h(Floors, {})
    });
  }

  if (elementContract != null) {
    const contractApp = new Vue({
      el: '#contractApp',
      store,
      i18n,
      render: (h) => h(Contracts, {})
    });
  }

  if (elementBatchCreateNewInvoiceApp != null) {
    const batchCreateNewInvoiceApp = new Vue({
      el: '#batchCreateNewInvoiceApp',
      store,
      i18n,
      render: (h) => h(BatchCreateNewInvoices, {
        props: {
          component: elementBatchCreateNewInvoiceApp.getAttribute('component')
        }
      })
    });
  }

  if (elementInvoice != null) {
    const invoiceApp = new Vue({
      el: '#invoiceApp',
      store,
      i18n,
      render: (h) => h(Invoices, {})
    });
  }

  if (elementPrintInvoiceApp != null) {
    const printInvoiceApp = new Vue({
      el: '#printInvoiceApp',
      store,
      i18n,
      render: (h) => h(PrintInvoice, {})
    });
  }

  if (elementNewInvoice != null) {
    const newInvoiceApp = new Vue({
      el: '#newInvoiceApp',
      store,
      i18n,
      render: (h) => h(NewInvoice, {})
    });
  }

  if (elementRevenueStatistics != null) {
    const revenueStatisticsApp = new Vue({
      el: '#revenueStatisticsApp',
      store,
      i18n,
      render: (h) => h(RevenueStatistics, {})
    });
  }

  if (elementContractHistoryStatistics != null) {
    const contractHistoryStatisticsApp = new Vue({
      el: '#contractHistoryStatisticsApp',
      store,
      i18n,
      render: (h) => h(ContractHistoryStatistics, {})
    });
  }

  if (elementListInvoices != null) {
    const listInvoicesApp = new Vue({
      el: elementListInvoices,
      store,
      i18n,
      render: (h) => h(ListInvoices, {})
    });
  }

  if (elementEditAdminProfileApp != null) {
    const editAdminProfileApp = new Vue({
      el: elementEditAdminProfileApp,
      store,
      i18n,
      render: h => h(EditAdminProfile, {})
    });
  }

  if (organizationsApp != null) {
    const organizations = new Vue({
      el: '#organizationsApp',
      store,
      i18n,
      render: (h) => h(Organizations, {})
    });
  }
});

function loadLocaleMessages() {
  return require(`../locales/modules/${locale}`).default;
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
