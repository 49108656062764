<template>
  <div class="card mb-5">
    <div
      class="card-header p-0"
      data-toggle="collapse"
      data-target="#collapseFormSearch"
    >
      <button
        class="btn dropdown-toggle"
        type="button"
      >
        <i class="fas fa-search" />
        <span class="font-weight-bold">{{ $t('revenues.search') }}</span>
      </button>
    </div>
    <div
      id="collapseFormSearch"
      class="collapse show"
    >
      <div class="card-body">
        <form
          id="search-revenues"
          @submit.prevent="submitForm"
        >
          <div class="form-group">
            <div
              class="btn-group"
              role="group"
            >
              <button
                type="button"
                :class="['btn', chooseTime ? 'btn-warning' : 'btn-light']"
                @click="chooseTime = true"
              >
                {{ $t('revenues.time') }}
              </button>
              <button
                type="button"
                class="btn mr-0 ml-0 pr-0 pl-0"
                disabled
              />
              <button
                type="button"
                :class="['btn', chooseTime ? 'btn-light' : 'btn-warning']"
                @click="chooseTime = false"
              >
                {{ $t('revenues.building_unit') }}
              </button>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-5">
              <div class="row">
                <label
                  class="col-3 col-form-label text-right"
                >{{ $t('revenues.report_type') }}</label>
                <div class="col-6">
                  <select
                    v-model="reportType"
                    class="custom-select"
                  >
                    <option
                      v-for="(option, index) in optionReportTypes"
                      :key="index"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div
              v-show="!chooseTime"
              class="col-5"
            >
              <div class="row">
                <label
                  class="col-3 col-form-label text-right"
                >{{ $t('revenues.building_unit') }}</label>
                <div class="col-6">
                  <select
                    id="buildingUnitId"
                    ref="building_unit_id"
                    v-model="buildingUnitId"
                  >
                    <option
                      v-for="(option, index) in optionBuildingUnits"
                      :key="index"
                      :value="option.id"
                    >
                      {{ option.unit_number }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-5">
              <div class="row">
                <label
                  class="col-3 col-form-label text-right"
                >{{ $t('revenues.start_date') }}</label>
                <div class="col-6">
                  <Datepicker
                    id="startDate"
                    v-model="startDate"
                    input-class="form-control"
                    calendar-class="w-100"
                    :language="locale[$i18n.locale]"
                    :format="formatType"
                    :minimum-view="reportType"
                    maximum-view="year"
                    :placeholder="$t('revenues.pla_start_date')"
                  />
                  <div
                    v-if="!startDate && showError"
                    class="invalid-feedback"
                  >
                    {{ $t('revenues.blank') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-5">
              <div class="row">
                <label
                  class="col-3 col-form-label text-right"
                >{{ $t('revenues.end_date') }}</label>
                <div class="col-6">
                  <Datepicker
                    id="endDate"
                    v-model="endDate"
                    input-class="form-control"
                    calendar-class="w-100"
                    :language="locale[$i18n.locale]"
                    :format="formatType"
                    :minimum-view="reportType"
                    maximum-view="year"
                    :placeholder="$t('revenues.pla_end_date')"
                  />
                  <div
                    v-if="!endDate && showError"
                    class="invalid-feedback"
                  >
                    {{ $t('revenues.blank') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2">
              <button
                class="btn btn-secondary text-nowrap"
              >
                {{ $t('revenues.filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('revenue');

import Datepicker from 'vuejs-datepicker';
import { en, vi } from 'vuejs-datepicker/dist/locale';
export default {
  components: {
    Datepicker
  },
  data() {
    return {
      reportType: 'month',
      formatType: 'MM/yyyy',
      buildingUnitId: '',
      startDate: '',
      endDate: '',
      locale: {
        'en': en,
        'vi': vi
      },
      chooseTime: true,
      showError: false
    };
  },
  computed: {
    ...mapState(['optionBuildingUnits', 'optionReportTypes'])
  },
  watch: {
    reportType(val) {
      if (val === 'year') {
        this.formatType = 'yyyy';
      } else {
        this.formatType = 'MM/yyyy';
      }
    }
  },
  mounted() {
    const self = this;
    return $(this.$refs.building_unit_id).select2({
      width: '100%',
      allowClear: true,
      placeholder: this.$t('revenues.pla_unit_number')
    }).on('select2:select select2:unselect', function() {
      self.buildingUnitId = this.value;
    }).on('select2:open', () => {
      $('.select2-search__field')[0].focus();
    });
  },
  methods: {
    ...mapActions(['submitFormSearch']),
    async submitForm() {
      this.showError = true;
      const page = 1;
      const params = {
        report_type: this.reportType,
        building_unit_id: this.chooseTime ? '' : this.buildingUnitId,
        start_date: this.startDate,
        end_date: this.endDate
      };
      $("#loading").removeClass("d-none");
      $("#body-content").addClass("body-opacity-50");
      await this.submitFormSearch({ params, page });
      $("#loading").addClass("d-none");
      $("#body-content").removeClass("body-opacity-50");
    }
  }
};
</script>

<style lang = "scss" scoped>
  .invalid-feedback {
    font-size: 90%;
    display: block;
  }
</style>
