import axios from './axios.js';

export default {
  loadWithCondition(params, cb) {
    axios.get('/users.json', {
      params: params,
      paramsSerializer: function(params) {
        return jQuery.param(params);
      }
    }).then(function(response) {
      cb(response.data);
    }).catch(function(error) {
      console.log(error.response);
    });
  },
  loadUsers(params, cb) {
    axios.get('/api/floors/users.json', {
      params: {
        building_unit_id: params.building_unit_id
      }
    }).then(function(response) {
      cb(response.data);
    }).catch(function(error) {
      console.log(error.response);
    });
  },
  async updateUser(params, cb) {
    await axios.patch(`/users/${params.get('user[id]')}.json`,
        params,
        { headers: {
          'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          'Content-Type': 'multipart/form-data'
          }
        }
    ).then(function(response) {
      cb(response.data);
    }).catch(function(error) {
      console.log(error.response);
    });
  },
  deleteUser(params, cb) {
    axios.delete(`/users/${params.id}.json`,
        { headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content') } }
    ).then(function(response) {
      cb(response.data);
    }).catch(function(error) {
      console.log(error.response);
    });
  },
  async restoreUser(params, cb) {
    await axios.patch(`/users/${params.id}/restore.json`, {},
        { headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content') } }
    ).then(function(response) {
      cb(response.data);
    }).catch(function(error) {
      console.log(error.response);
    });
  },
  loadBuildingUnits(params, cb) {
    axios.get('/api/users/building_units.json', {
      params: params
    }).then(function(response) {
      cb(response.data);
    }).catch(function(error) {
      console.log(error.response);
    });
  },
  async createUsers(params, cb) {
    await axios.post('/users.json',
        params,
        { headers: {
          'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          'Content-Type': 'multipart/form-data'
        } }
    ).then(function(response) {
      cb(response.data);
    }).catch(function(error) {
      console.log(error.response);
    });
  },
  importFile(params, cb) {
    axios.post('/batch_create/users.json', params,
        { headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content') } }
    ).then(function(response) {
      cb(response.data);
    }).catch(function(error) {
      console.log(error.response);
    });
  },
  loadDetailUser(params, cb) {
    axios.get(`/users/${params.id}.json`).then(function(response) {
      cb(response.data);
    }).catch(function(error) {
      console.log(error.response);
    });
  }
};
