<template>
  <div class="form-inline">
    <div class="form-group">
      <label class="col-form-label mr-2">{{ $t('per_page.show') }}</label>
      <select
        v-model="perPage"
        class="form-control"
        @change="changePerPage"
      >
        <option
          v-for="option in options"
          :key="option"
          :value="option"
        >
          {{ option }}
        </option>
      </select>
      <label class="col-form-label ml-2">{{ $t('per_page.items') }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initPerPage: {
      type: Number,
      default: 10
    },
    options: {
      type: Array,
      default: () => [10, 20, 30]
    }
  },
  data() {
    return {
      perPage: 0
    };
  },
  mounted: function() {
    this.perPage = this.initPerPage;
  },
  methods: {
    changePerPage() {
      this.$emit('change', this.perPage);
    }
  }
};
</script>
