<template>
  <div
    id="showUser"
    class="modal fade"
    role="dialog"
    aria-modal="true"
    aria-hidden="true"
    data-backdrop="static"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t('user.show_user') }} <strong>{{ currentUser.full_name }}</strong>
          </h5>
        </div>
        <div class="d-flex justify-content-center p-2">
          <div
            ref="profilePic"
            class="profile-pic"
          />
        </div>
        <div class="modal-body">
          <FieldConfirm
            :label-text="$t('user.first_name')"
            :value="params.first_name"
          />
          <FieldConfirm
            :label-text="$t('user.last_name')"
            :value="params.last_name"
          />
          <FieldConfirm
            :label-text="$t('user.email')"
            :value="params.email"
          />
          <FieldConfirm
            :label-text="$t('user.birthday')"
            :value="params.birthday"
          />
          <FieldConfirm
            :label-text="$t('user.phone')"
            :value="params.phone"
          />
          <FieldConfirm
            :label-text="$t('user.identity_card')"
            :value="params.identity_card"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            {{ $t('user.close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('user');
import FieldConfirm from '../components/fieldConfirm';

export default {
  components: {
    FieldConfirm
  },
  data: function() {
    return {
      params: {},
      defaultAvatar: 'https://d22tsqse9dyb2k.cloudfront.net/sample_icon.png'
    };
  },
  computed: {
    ...mapState(['currentUser'])
  },
  watch: {
    currentUser: function(val) {
      this.params = {
        id: val.id,
        birthday: val.birth_day,
        email: val.email,
        first_name: val.first_name,
        last_name: val.last_name,
        phone: val.phone,
        identity_card: val.identity_card
      };
      if (val.avatar?.url) {
        this.$refs.profilePic.style.backgroundImage = `url(${val.avatar.url})`;
      } else {
        this.$refs.profilePic.style.backgroundImage = `url(${this.defaultAvatar})`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  $circleSize: 10em;
  $radius: 50%;
  $fontColor: white;
  $shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px, rgb(209, 213, 219) 0 0 0 1px inset;;

  .profile-pic {
    position: relative;
    color: transparent;
    width: $circleSize;
    height: $circleSize;
    border-radius: $radius;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    text-align: center;
    box-shadow: $shadow;

    input {
      display: none;
    }

    label {
      width: $circleSize;
      height: $circleSize;
      border-radius: $radius;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: $shadow;
      z-index: 10;
    }

    span {
      display: inline-block;
    }

    label:hover {
      background-color: rgba(0,0,0,.5);
      z-index: 10000;
      color: $fontColor;
      transition: background-color .2s ease-in-out;
      border-radius: $radius;
    }
  }
</style>
